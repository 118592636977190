import React from "react"
import SEO from "../components/Seo"
import Image from "../components/Image"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"

const VFTPage = () => {
  return (
    <Layout>
      <SEO title="Virtual Field Trip" />

      {/***************  HERO ***************/}
      <Section className="hero">
        <Container>
          <Row>
            <Column span={7}>
              <h1>Virtual Field Trip</h1>
              <h3 className="hero__subhead">
                Join us for a real-world virtual learning opportunity to get the
                facts on the vaping epidemic.
              </h3>
            </Column>
          </Row>
        </Container>
      </Section>

      {/***************  MAIN BODY ***************/}
      <Section>
        <Container>
          <h2 className="serif">Virtual Field Trip Series</h2>
          <Row>
            <Column span="4">
              <Image filename="poster/img-vft-still-2022.jpg" />
            </Column>
            <Column span="8">
              <h2>Heroes: A Be Vape Free Virtual Field Trip </h2>
              <p>
                Celebrate real-life heroes who are helping to spread the word about the dangers of vaping using everything from social media to dynamic in-person interactions. This epic virtual journey introduces students to courageous and creative anti-vaping crusaders who are empowering healthy decisions in their communities and beyond, including subject matter experts and teen advocates.
              </p>
              <p>
                Explore a variety of unique perspectives into the harmful impacts of e-cigarettes on the brain and body with authentic stories from around the country, and get an up-close look at what everyday heroism looks like.
              </p>
              <p>
                <a href="/heroes-vft" className="green__link">
                  WATCH NOW ›
                </a>
              </p>
            </Column>
          </Row>
          <Row className="mt-2">
            <Column span="4">
              <Image filename="theme/posters/just-the-facts/video-01.jpg" />
            </Column>
            <Column span="8">
              <h2>Just The Facts: A Be Vape Free Virtual Field Trip</h2>
              <p>
                Get ready for an exciting virtual detective story that lets your
                students become E-cigarette Sleuths cracking the case of vaping once 
                and for all! Introduce students to an array of amazing special 
                guests, and dive into the science of addiction by tracking vape 
                aerosol on its nefarious journey from the e-cigarette all the way 
                into the lungs, bloodstream, and brain.
              </p>
              <p>
                Witness the thrilling conclusion of the great vape mystery by watching today! You can get
                your Vape Investigators excited both before and after watching the 
                Virtual Field Trip with the companion Educator Guide.
              </p>
              <p>
                <a href="/just-the-facts-vft" className="green__link">
                  WATCH NOW ›
                </a>
              </p>
            </Column>
          </Row>
          <Row className="mt-2">
            <Column span="4">
              <Image filename="poster/cvs-vft-0.jpg" />
            </Column>
            <Column span="8">
              <h2>Clearing The Air: A Be Vape Free Virtual Field Trip</h2>
              <p>
                Join us for a real-world virtual learning opportunity to get the
                facts on the vaping epidemic. Students will gain powerful
                insights as several teenagers share their firsthand experiences
                of successfully avoiding e-cigarettes, learn how to think
                critically about everyday influences like advertising and social
                media, pick up effective refusal skills and debunk common myths
                with a health professional. Be sure to share this important
                virtual learning experience with your students, as those who use
                e-cigarettes are at increased risk of complications from
                respiratory illnesses like COVID-19!
              </p>
              <p>
                <a href="/clearing-the-air-vft" className="green__link">
                  WATCH ON DEMAND ›
                </a>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default VFTPage
